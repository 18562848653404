"use client";

// Import Library
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// Import Custom Library
import { store } from '@/core/store';

// Import Component
import NotFoundPage from "@/app/(root)/not-found-page";

export default function NotFound({ enableIdealPay, helpContent, listFAQGroup }) {
    return (
        <ReduxProvider store={store}>
            <PersistGate persistor={store.__persistor}>
                <NotFoundPage enableIdealPay={enableIdealPay} helpContent={helpContent} listFAQGroup={listFAQGroup} />
            </PersistGate>
        </ReduxProvider>
    )
}
