import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  developer: null,
  property: null,
  unit: null,
  quizData: null,
  results: [],
  multiguna: null,
  praIdealScore: null,
  primaryKpr: null,
  propertyCategory: {},
  secondaryKpr: null,
  takeoverKpr: null,
  takeoverMultiguna: null,
  sidebar: true,
  isIgnoreCompassLog: false,
};

const kompasSlice = createSlice({
  name: 'kompas',
  initialState,
  reducers: {
    setDeveloper: (state, action) => {
      state.developer = action.payload;
    },
    setIgnoreCompassLog: (state, action) => {
      state.isIgnoreCompassLog = action.payload;
    },
    setMultiguna: (state, action) => {
      state.multiguna = action.payload;
    },
    setProperty: (state, action) => {
      state.property = action.payload;
    },
    setPropertyCategory: (state, action) => {
      state.propertyCategory = action.payload;
    },
    setUnit: (state, action) => {
      state.unit = action.payload;
    },
    setIdealScore: (state, action) => {
      state.idealScore = action.payload;
    },
    setPrimaryKpr: (state, action) => {
      state.primaryKpr = action.payload;
    },
    setResults: (state, action) => {
      state.results = action.payload;
    },
    setSecondaryKpr: (state, action) => {
      state.secondaryKpr = action.payload;
    },
    setTakeoverKpr: (state, action) => {
      state.takeoverKpr = action.payload;
    },
    setTakeoverMultiguna: (state, action) => {
      state.takeoverMultiguna = action.payload;
    },
    setQuizData: (state, action) => {
      state.quizData = action.payload;
    },
    setSidebar: (state, action) => {
      state.sidebar = action.payload;
    },
    clearKompasData: (state) => {
      state.developer = null;
      state.property = null;
      state.unit = null;
      state.multiguna = null;
      state.idealScore = null;
      state.primaryKpr = null;
      state.secondaryKpr = null;
      state.takeoverKpr = null;
      state.takeoverMultiguna = null;
    },
    clearData: (state) => {
      state.developer = null;
      state.property = null;
      state.unit = null;
      state.quizData = null;
      state.results = [];
      state.multiguna = null;
      state.idealScore = null;
      state.propertyCategory = {};
      state.primaryKpr = null;
      state.secondaryKpr = null;
      state.takeoverKpr = null;
      state.takeoverMultiguna = null;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addDefaultCase((state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload.auth,
  //     };
  //   });
  // },
});

export const {
  setDeveloper,
  setIgnoreCompassLog,
  setMultiguna,
  setProperty,
  setUnit,
  setIdealScore,
  setPrimaryKpr,
  setResults,
  setPropertyCategory,
  setSecondaryKpr,
  setTakeoverKpr,
  setTakeoverMultiguna,
  setQuizData,
  setSidebar,
} = kompasSlice.actions;

export default kompasSlice.reducer;

export const getDeveloper = (state) => state.kompas.developer;
export const getIgnoreCompassLog = (state) => state.kompas.isIgnoreCompassLog;
export const getIsApplication = (state) => !!(state.kompas.primaryKpr || state.kompas.property || state.kompas.secondaryKpr || state.kompas.takeoverKpr || state.kompas.multiguna || state.kompas.takeoverMultiguna);
export const getIsPrimaryKPR = (state) => ((state.kompas.primaryKpr || state.kompas.property) && !state.kompas.secondaryKpr && !state.kompas.takeoverKpr && !state.kompas.multiguna && !state.kompas.takeoverMultiguna);
export const getIsSecondaryKPR = (state) => (!state.kompas.primaryKpr && !state.kompas.property && state.kompas.secondaryKpr && !state.kompas.takeoverKpr && !state.kompas.multiguna && !state.kompas.takeoverMultiguna);
export const getIsTakeoverKPR = (state) => (!state.kompas.primaryKpr && !state.kompas.property && !state.kompas.secondaryKpr && state.kompas.takeoverKpr && !state.kompas.multiguna && !state.kompas.takeoverMultiguna);
export const getIsTakeoverMultiguna = (state) => (!state.kompas.primaryKpr && !state.kompas.property && !state.kompas.secondaryKpr && !state.kompas.takeoverKpr && !state.kompas.multiguna && state.kompas.takeoverMultiguna);
export const getIsMultiguna = (state) => (!state.kompas.primaryKpr && !state.kompas.property && !state.kompas.secondaryKpr && !state.kompas.takeoverKpr && state.kompas.multiguna && !state.kompas.takeoverMultiguna);
export const getMultiguna = (state) => state.kompas.multiguna;
export const getProperty = (state) => state.kompas.property;
export const getUnit = (state) => state.kompas.property;
export const getIdealScore = (state) => state.kompas.idealScore;
export const getPropertyCategory = (state) => state.kompas.propertyCategory;
export const getPrimaryKpr = (state) => state.kompas.primaryKpr;
export const getResults = (state) => state.kompas.results;
export const getSecondaryKpr = (state) => state.kompas.secondaryKpr;
export const getTakeoverKpr = (state) => state.kompas.takeoverKpr;
export const getTakeoverMultiguna = (state) => state.kompas.takeoverMultiguna;
export const getQuizData = (state) => state.kompas.quizData;
export const getSidebar = (state) => state.kompas.sidebar;
