import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  count: 0,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
      state.count = action.payload?.length || 0;
    },
    addItem: (state, action) => {
      const itemData = action.payload;
      const newItems = [itemData, ...state.items];

      state.items = newItems;
      state.count = newItems.length;
    },
    addItems: (state, action) => {
      const addedItems = action.payload;
      const currentItems = state.items;
      const newItems = addedItems.filter((check) => !currentItems.find((findCheck) => findCheck?.id === check?.id));
      const allItems = [...newItems, ...currentItems];

      state.items = allItems;
      state.count = allItems.length;
    },
    changeReadStatus: (state, action) => {
      const { id, status } = action.payload;
      const currentItems = state.items;
      const msgDetailKey = currentItems.findIndex((check) => check.id === id);
      if (msgDetailKey !== -1) {
        currentItems[msgDetailKey].isRead = status;
      }
      state.items = currentItems;
    },
    changeAllReadStatus: (state, action) => {
      const currentItems = state.items;
      currentItems.forEach((message) => {
        message.isRead = action.payload;
      })
      state.items = currentItems;
    },
    removeItem: (state, action) => {
      const id = action.payload;
      const newItems = state.items.filter((check) => check.id !== id);

      state.items = newItems;
      state.count = newItems.length;
    },
    clearNotifications: (state) => {
      state.items = [];
      state.count = 0;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addDefaultCase((state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload.auth,
  //     };
  //   });
  // },
});

export const { setItems, addItem, addItems, removeItem, changeReadStatus, changeAllReadStatus, clearNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;

export const getItems = (state) => state.notification.items;
export const getUnreadItems = (state) => {
  return state.notification.items.filter((item) => !item?.isRead);
};
export const getCount = (state) => state.notification.count;
