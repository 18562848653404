import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstTakeover: true
};

const nudgesSlice = createSlice({
  name: 'nudges',
  initialState,
  reducers: {
    setFirstTakeover: (state, action) => {
      state.firstTakeover = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addDefaultCase((state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload.auth,
  //     };
  //   });
  // },
});

export const { setFirstTakeover } = nudgesSlice.actions;

export default nudgesSlice.reducer;

export const getFirstTakeover = (state) => state.nudges.firstTakeover;